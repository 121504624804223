
import Vue from "vue";
import { isNull, isUndefined, isEmpty } from "lodash";
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import AuthSkeleton from "@/components/Commons/Skeletons/Auth/index.vue";
// @ts-ignore
import { setStyleSheets } from "@/utils/styles";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { changeQueryLanguage } from "@/router";

export default Vue.extend({
	name: "LayoutAuth",
	data: () => ({
		languages: [
			{ key: "en", value: "English" },
			{ key: "es", value: "Español" },
			{ key: "pt", value: "Português" },
		],
	}),
	components: { Alertize, AuthSkeleton },
	created() {
		this.$nextTick(async () => {
			await this.setLoadingData(TypeLoading.loading);
			await this.setLookAndFeel();
			this.changeLanguage(this.hasLanguaje ? this.getLang : this.getLanguage);
			await this.setLoadingData();
		});
	},
	computed: {
		...mapGetters("loading", ["isLoading"]),
		...mapGetters("internationalization", ["getLanguage"]),
		...mapGetters("profile", ["lookFeel"]),

		getImage(){
			return this.lookFeel?.logo ?? "";
		},

		hasLanguaje() {
			return isEmpty(this.$route.query) ? false : !isEmpty(this.$route.query.language);
		},
		getLang() {
			return this.$route.query.language;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("profile", ["fetchLookAndFeel"]),
		...mapActions("internationalization", ["setLanguage"]),

		async setLookAndFeel() {
			const response = await this.fetchLookAndFeel({
				domain: window.location.hostname,
			});
			if (isNull(response) || isUndefined(response)) return;
			await setStyleSheets(response);
		},

		changeLanguage(language: string) {
			this.setLanguage({id: NaN, language: language});
			changeQueryLanguage(language);
		},
	},
});
